import React from "react";
import MainBlogDetailsComponent from "../../component/blog/MainBlogDetailsComponent";

const ChassisNoBlog: React.FC = () => {
  return (
    <div className="auto-container">
      <MainBlogDetailsComponent
        contentOne={{
          category: "Oto Yedek Parça",
          title: "Şase Numarası İle Yedek Parça Sorgulama",
          image: "assets/images/product/chassis-1.webp",
          text1:
            "Şase numarası ile yedek parça sorgulama, araçların %100 doğru yedek parçalarının listelendiği DunyaParca sistemidir. Sorgulama sonucunda karşınıza gelen yedek parçalar girilen şase no ile birebir uyumludur. Şase programı sayesinde parçaların orijinal, yan sanayi veya muadil olarak fiyatlarını görebilir, OEM (Orjinal parça numarası) numaraları görebilirsiniz.",
          text2:
            'Şase no ile yedek parça bulma için seçtiğiniz arabanızı www.dunyaparca.com.tr sayfa içinde görebilirsiniz. Başka şase numarasından yedek parça sorgulama yapabilir veya şasi nodan sorgulamayla bulamadığınız yan sanayi ve orijinal parçaları "Araçdan Parça Bul" ile marka, model, üretim yılı, motor, vites, yakıt, kasa gibi seçim adımlarıyla araç özelliklerinden oto yedek parçalarına ulaşabilirsiniz. Aynı ürün için parçaların markalarına ve üretici firmalara göre fiyatlar değişiklik gösterebilmektedir.',
        }}
        contentTwo={{
          heading: "Şase Numarası İle Yedek Parça Nasıl Bulunur?",
          paragraph1:
            "Site içerisinde şaseden parça bul kısmına tıklayınız. Araç şase numarasını yazıp araç bul butonuna basınız. Sistem otomatik olarak aracın teknik bilgilerine ulaşıp yedek parçalar ile eşleyerek belli kategorilerde sınıflayarak karşınıza çıkarmaktadır. Aracınıza yüzde yüz uyumlu yedek parcalar ekranda listelenir. Şaşe numarasıyla yedek parça bul yaptığınız halde şase nodan sorgulamayla parçaları bulamadığınız zaman Araç dan Parça Bul ile aracınız marka, model, üretim yılı, motor, vites gibi bilgileri adım adım seçerek araç özellikleri ile araba yedek parçaları bulabilirsiniz.",
          images: [
            "assets/images/product/chassis-3.webp",
            "assets/images/product/chassis-4.webp",
          ],
          quote: {
            text: "Şaseden veya araç bilgileriyle parçaları bulamazsanız veya herhangi bir tereddüt yaşarsanız 0850 840 34 00 müşteri hizmetlerinden yardım alabilirsiniz. Doğru parça için araç şase numarasını mutlaka yanınızda bulundurunuz.",
            author: "Yedek Parça Uzmanı",
          },
          paragraph2:
            "Dilerseniz whatsapp numaramıza( 0850 840 34 00 ) istediğiniz parçayı ve şase numaranızı gönderebilirsiniz. Yedek parça uzmanlarımız sizinle irtibata geçerek en doğru bilgiyi sizinle paylaşacaklardır.",
        }}
        contentThree={{
          title: "Araç Şase Numarası Nedir? Şase Numarası Ne İşe Yarar?",
          text: "Yedek parça satıcıları ve araç sahipleri için, hayati öneme sahip olan şase numarası ve kullanım amacı ile ilgili size kısa bilgilendirme yapmak isteriz.",
          listItems: [
            "Araç Kimlik Numarası (Vehicle Identification Number - VIN), şase numarası ile araca has aracın üretici firması, üretildiği yıl ve yeri, kullanım amacı, motor hacmi, rengi, kasa kaporta bilgileri, arabada kullanılan yedek parçalar gibi. Teknik bilgiler kayıt altına alınmış ve aracın idaresi kısmında; servis bakım kayıtları, kayıtlı trafik kazaları ve sigorta bilgileri ve araç fenni muayene bilgileri, şasi numarası üzerinde işlenir.",
            "dunyaparca.com.tr ailesi olarak 30 yıldır hizmet verdiğimiz perakende yedek parça tecrübemizi ve 450.000 yakın tekil parça birikimimizi geliştirdiğimiz sistem ile şase numaralarına eşleyip siz araç sahiplerini ihtiyaç duyduğu oto yedek parçaları ile ilgi fiyat, parça bilgisi, kullanıldığı yer ve görevi, orijinal ve yan sanayi muadilleri ile ilgili bilgi alıp isterseniz sistem üzerinden gönül rahatlığı ile satın alabilirsiniz.",
            "Araç üretici firmalar tarafından üretim aşamasında, otomotiv sektöründe üretim ve üretim sonrası bilgilerin işlendiği numaradır. Daha açıklayıcı olmak bakımdan; doğum sonrası doğduğun ülke tarafından kişiye özel verilen isim, doğum yeri ve yılı, doğum tarihi gibi. T.C numarası ile kayıt altına alınıyorsa ve günlük hayatımızın sevk ve idaresini nasıl kullanıyorsak, araç şase numarası da otomobiller için, kimlik numarasının karşılığıdır.",
          ],
        }}
      />
    </div>
  );
};

export default ChassisNoBlog;
