import React from "react";
import { selectUser, updateProfileAsync } from "../../../redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useAppDispatch } from "../../../redux/hooks";

interface EditProfileState {
  name: string;
  surname: string;
  phone: string;
  email: string;
}

const EditProfile = () => {
    const user = useSelector(selectUser);
    const dispatch = useAppDispatch()
    // formdaki verileri state'de tutmak için useState kullanılabilir
    const [editProfileState, setEditProfileState] = React.useState<EditProfileState>({
        name: user?.user?.firstName || "",
        surname: user?.user?.lastName|| "",
        phone: user?.user?.phone|| "",
        email: user?.user?.email|| "",
    });
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditProfileState({
            ...editProfileState,
            [e.target.id]: e.target.value,
        });
    };
    const handleSubmit = (e:React.FormEvent<EditProfileState & HTMLFormElement>) => {
        e.preventDefault();
        // form submit işlemleri
        dispatch(updateProfileAsync({
            email: editProfileState.email,
            phoneNumber: editProfileState.phone,
            firstName: editProfileState.name,
            lastName: editProfileState.surname,
            avatar: user?.user?.avatar || "",
        }));

    }
  return (
    <div className="card">
      <div className="card-body">
        <div className="card-title">
          <h5>Profili Düzenle</h5>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Adınız</label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Adınız"
              value={editProfileState.name}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="surname">Soyadınız</label>
            <input
              type="text"
              className="form-control"
              id="surname"
              placeholder="Soyadınız"
              value={editProfileState.surname}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Telefon Numaranız</label>
            <input
              type="text"
              className="form-control"
              id="phone"
              placeholder="Telefon Numaranız"
              value={editProfileState.phone}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">E-posta Adresiniz</label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="E-posta Adresiniz"
              value={editProfileState.email}
              onChange={handleChange}
            />
          </div>
          <button type="submit" className="btn">
            Kaydet
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditProfile;
