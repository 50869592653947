import Footer from './component/footer/Footer';
import Header from './component/header/Header';
import MobileMenu from './component/mobileMenu/MobileMenu';
import SearchPopup from './component/searchPopup/SearchPopup';
import Body from './pages/Body';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';


function App() {
  return (
    <div className="boxed_wrapper">
      <SearchPopup/>
      <Header/>
      <MobileMenu/>
      <Body/>
      <ToastContainer/>
      <Footer/>
    </div>
  );
}

export default App;
