import React from "react";
import { IMyVehicle } from "../../interfaces/garage";
import "./SelectedVehicle.scss";

interface ISelectedVehicleProps {
  myVehicle: IMyVehicle;
  onClose?: () => void;
}

export default function SelectedVehicle({
  myVehicle: { id, vehicle, vehicleName, year },
  onClose,
}: ISelectedVehicleProps) {
  return (
    <div key={id} className="vehicles-list__item card">
      <div className="vehicles-list_item-image mr-1">
        <img
          src={vehicle?.images?.[0] || "/assets/images/garage-car.webp"}
          alt={vehicle.model.name}
        />
      </div>
      {onClose && (
        <button className="vehicles-list__item-close" onClick={onClose}>
          x
        </button>
      )}
      <div className="vehicles-list__item-info">
        <div className="vehicles-list__item-title">{vehicleName}</div>
        <div className="vehicles-list__item-name">
          {`${vehicle.model.name} ${vehicle.model.brand?.name || ""} (${year === undefined ? vehicle.year : year}) ${vehicle.bodyType}`}
        </div>
        <div className="vehicles-list__item-details">
          {"Motor: " +
            vehicle.engine +
            " " +
            vehicle.fuelType +
            " " +
            vehicle.horsepowerKw}
        </div>
      </div>
    </div>
  );
}
