import React, { useEffect } from "react";
import "./Banner.scss";
import BannerVehicleSelect from "./BannerVehicleSelect";
import { IMyVehicle, IVehicle } from "../../interfaces/garage";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../redux/hooks";
import {
  clearSelectedMyVehicle,
  fetchVehiclesAsync,
  selectSelectedMyVehicle,
  selectVehicles,
  setSelectedMyVehicle,
  setSelectedVehicle,
} from "../../redux/slices/garageSlice";
import SelectedVehicle from "../selectedVehicle/SelectedVehicle";
import MyVehicleSelect from "../../pages/maintainRobot/MyVehicleSelect";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/userSlice";
export default function Banner() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [vehicle, setVehicle] = React.useState<IVehicle | undefined>(undefined);
  const user = useSelector(selectUser);
  const isUserAuth = !!user?.user;
  const vehicles = useSelector(selectVehicles);
  const selectedMyVehicle = useSelector(selectSelectedMyVehicle);

  useEffect(() => {
    if (isUserAuth && (!vehicles || vehicles.length === 0)) {
      dispatch(fetchVehiclesAsync());
    }
  }, [selectedMyVehicle, selectedMyVehicle?.id]);

  const setMyVehicle = (vehicle: IMyVehicle) => {
    dispatch(setSelectedMyVehicle(vehicle));
  };

  const handleAddVehicle = (vehicle: IVehicle, year: number) => {
    vehicle.year = year;
    setVehicle(vehicle);
  };
  const handleFindPartsVehicle = () => {
    if (!vehicle) {
      return;
    }
    dispatch(setSelectedVehicle(vehicle));
    navigate("/vehicle-product");
  };

  const handleFindPartsMyVehicle = () => {
    if (!selectedMyVehicle) {
      return;
    }
    dispatch(setSelectedMyVehicle(selectedMyVehicle));
    navigate("/vehicle-product");
  };

  const onVehicleClose = () => {
    dispatch(clearSelectedMyVehicle());
  };

  return (
    <section className="banner banner-two">
      <div className="row">
        <div className="banner-left-container">
          <div className="mt-5">
            <div className="row mt-5">
              <div className="col-md-6 col-sm-12 p-5">
                {isUserAuth ? (
                  <div className="container">
                    <div className="banner-right-container-top">
                      <div className="banner-right-car">
                        <img src="/assets/images/icons/car-1.png" alt="car" />
                      </div>
                      <div className="banner-right-title">
                        <h6>Garajım İçinde Ara.</h6>
                        <h5>Garajdaki Aracının Parçaları</h5>
                      </div>
                    </div>

                    {selectedMyVehicle && selectedMyVehicle?.vehicle?.id ? (
                      <div className="mb-3">
                        <SelectedVehicle
                          myVehicle={selectedMyVehicle}
                          onClose={onVehicleClose}
                        />
                      </div>
                    ) : (
                      <MyVehicleSelect
                        vehicleList={vehicles}
                        selectedVehicle={selectedMyVehicle}
                        setSelectedVehicle={setMyVehicle}
                      />
                    )}
                    <div className="select-btn">
                      <button
                        onClick={handleFindPartsMyVehicle}
                        disabled={!selectedMyVehicle?.vehicle?.id}
                        className="theme-btn btn-style-one"
                      >
                        Garajındaki Aracına Yedek Parça Bul
                        <i className="flaticon-right-arrow"></i>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="container">
                    <div className="banner-right-container-top">
                      <div className="banner-right-car">
                        <img src="/assets/images/icons/car-1.png" alt="car" />
                      </div>
                      <div className="banner-right-title">
                        <h6>Garajım İçinde Ara.</h6>
                        <h5>Garajdaki Aracının Parçaları</h5>
                      </div>
                    </div>
                    <Link to="/login" className="theme-btn btn-style-one">
                      Üye Girişi Yap
                    </Link>
                    <div>
                      <span>veya</span>
                    </div>
                    <Link to="/register" className="theme-btn btn-style-one">
                      Üye Ol
                    </Link>
                  </div>
                )}
              </div>
              <div className="col-md-6 col-sm-12 p-5">
                <div className="container">
                  <div className="banner-right-container-top">
                    <div className="banner-right-car">
                      <img src="/assets/images/icons/car-1.png" alt="car" />
                    </div>
                    <div className="banner-right-title">
                      <h6>Arabalar İçinde Ara.</h6>
                      <h5>Arabanın Parçaları</h5>
                    </div>
                  </div>
                  <div className="select-container">
                    {!vehicle && (
                      <BannerVehicleSelect onSubmit={handleAddVehicle} />
                    )}
                  </div>
                  {vehicle && (
                    <SelectedVehicle
                      myVehicle={{ vehicle } as IMyVehicle}
                      onClose={() => setVehicle(undefined)}
                    />
                  )}
                  <div className="select-btn">
                    <button
                      onClick={handleFindPartsVehicle}
                      disabled={!vehicle}
                      className="theme-btn btn-style-one"
                    >
                      Yedek Parça Bul<i className="flaticon-right-arrow"></i>
                    </button>
                  </div>
                  <div className="select-shape">
                    <img
                      src="/assets/images/shape/select-shape.png"
                      alt="shape"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="banner-item">
            <div className="banner-content">
              <h6>Özel Fırsatlar Bu Hafta</h6>
              <h2>
                En Yeni Ürünlerimizle Tanışın <br /> Üstün Kalite, Rakipsiz
                Fiyatlar
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
