import React, { useEffect, useMemo, useState } from "react";
import PageTitle from "../../component/pageTitle/PageTitle";
import OrderBody from "../../component/orderBody/OrderBody";
import { useSelector } from "react-redux";
import { selectCart } from "../../redux/slices/cartSlice";
import { fetchShippingPrices } from "../../redux/api/orderApi";
import { fetchAddressListAsync } from "../../redux/slices/addressSlice";
import { useAppDispatch } from "../../redux/hooks";

export default function Order() {
  const cart = useSelector(selectCart);
  const dispatch = useAppDispatch();

  const [shippingCompanyList, setShippingCompanyList] = useState([]);
  useEffect(() => {
    fetchShippingPrices().then((res) => {
      setShippingCompanyList(res);
    });
    dispatch(fetchAddressListAsync());
  }, []);

  const cartItems = cart.items.map((c) => ({
    id: c.product.id,
    price: c.price,
    productName: c.product.name,
    quantity: c.quantity,
  }));
  return (
    <div>
      <PageTitle title="Sipariş Onay" />
      <OrderBody
        cartItems={cartItems}
        shippingCompanyList={shippingCompanyList}
      />
    </div>
  );
}

