import React from "react";
import { IMyVehicle } from "../../interfaces/garage";

interface MyVehicleSelectProps {
  vehicleList: Array<IMyVehicle>;
  selectedVehicle: IMyVehicle | undefined;
  setSelectedVehicle: (vehicle: IMyVehicle) => void;
}

export default function MyVehicleSelect(props: MyVehicleSelectProps) {
  return (
    <div className="d-flex">
      <select
        className="form-control"
        onChange={(e) =>
          props.setSelectedVehicle(
            props.vehicleList.find(
              (myVehicle) => myVehicle.id + "" === e.target.value,
            ) as IMyVehicle,
          )
        }
      >
        <option defaultChecked value="">
          Garajdan Araç Seçiniz
        </option>
        {props.vehicleList.map((myVehicle) => (
          <option key={myVehicle.vehicle.id} value={myVehicle.id} selected={props.selectedVehicle?.id === myVehicle.id}>
            {myVehicle.vehicleName} {myVehicle.vehicle.vehicleName} ({myVehicle.vehicle.model.name} {myVehicle.vehicle.model.brand?.name} {myVehicle.vehicle.year}) 
          </option>
        ))}
      </select>
    </div>
  );
}
