import { useEffect, useState } from "react";
import ProductGrid from "../../component/productGrid/ProductGrid";
import {
  fetchProducts,
  fetchProductsByKey,
} from "../../redux/api/productApi";
import InputBlock from "../../component/inputBlock/InputBlock";
import { useParams } from "react-router-dom";
import ChassisNoBlog from "./ChassisNoBlog";
import { findByVehicleIdentificationId } from "../../redux/api/vehicleApi";
import { IProduct, IProductResponse } from "../../interfaces/product";
import { useSelector } from "react-redux";
import { selectSelectedCategory } from "../../redux/slices/productSlice";
import { selectVin, setVin } from "../../redux/slices/garageSlice";
import { useAppDispatch } from "../../redux/hooks";

export default function SearchProductChassisNo() {
  const { chassisNumber } = useParams();
  const dispatch = useAppDispatch();
  const selectedCategory = useSelector(selectSelectedCategory);
  const categoryId = selectedCategory?.url;
  const [productList, setProductList] = useState<IProductResponse>({} as IProductResponse)
  const searchText = useSelector(selectVin) || "";
  const setSearchText = (vin?: string) => {
    dispatch(setVin(vin ? vin : ""));
  };
  const [page, setPage] = useState(1);
  const [selectedVehicle, setSelectedVehicle] = useState(undefined);

  useEffect(() => {
    if (chassisNumber) {
      setSearchText(chassisNumber);
      handleOnSearch(chassisNumber);
    }
  }
  ,[chassisNumber]);

  useEffect(() => {
    if (searchText) {
      handleOnSearch(searchText);
    }
  }, [categoryId, page]);

  const handleOnSearch = (searchVal:string): void => {
    findByVehicleIdentificationId(searchVal).then((response) => {
      if (!response) {
        return;
      }
      setSelectedVehicle(response);
      fetchProducts({
        sortAsc: false,
        limit: 12,
        page: page,
        slug: "",
        categoryId:categoryId !== undefined ? categoryId + "" : undefined,
        vehicleId: response.id + "",
      }).then((response) => {
        setProductList(response);
      });
    });
  };

  return (
    <div>
      <InputBlock
        titleTag={"%100 Uyumlu Parçalar"}
        title={"Şase Numarası İle Yedek Parça Sorgulama"}
        inputPlaceholder="Aracınıza Uyumlu Parçaları Listelemek için Şase Numarasını Giriniz"
        inputText={searchText}
        searchButton={"araç bul"}
        imageUrl="assets/images/product/chassis-3.webp"
        setInputText={function (inputText: string): void {
          setSearchText(inputText);
        }}
        onSearch={handleOnSearch}
        description={
          "Girilen şasi numarasına göre listelenen yedek parçalar tam uyumludur. Şasi programı sayesinde parçaların orijinal, yan sanayi veya muadil olarak fiyatlarını ve OEM (Orijinal Parça Numarası) numaralarını görebilirsiniz."
        }
      />
      {productList && productList.items && productList.items.length !== 0 && (
        <ProductGrid
        />
      )}
      <ChassisNoBlog />
    </div>
  );
}
