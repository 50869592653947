import { fetchService, serverUrl } from "./fetchService";

// Function to fetch cart items
export async function fetchOrderItems() {
  const path = "/Order/getOrderList";
  return await fetchService({ url: serverUrl + path, method: "GET" });
}

// /Orders/getShippingPrices
export async function fetchShippingPrices() {
  const path = "/Orders/getShippingPrices";
  return await fetchService({
    url: serverUrl + path,
    method: "GET",
    throwErrors: false,
  });
}

// /Orders/setShippingCompany?shippingCompany=MNGKargo
export async function setShippingCompany(shippingCompany: string) {
  const path = `/Orders/setShippingCompany?shippingCompany=${shippingCompany}`;
  return await fetchService({
    url: serverUrl + path,
    method: "POST",
    throwErrors: false,
  });
}

// /Orders/setOrderShippingAddress?addressId=1
export async function setOrderShippingAddress(addressId: number) {
  const path = `/Orders/setOrderShippingAddress?addressId=${addressId}`;
  return await fetchService({
    url: serverUrl + path,
    method: "POST",
    throwErrors: false,
  });
}

// /Orders/setOrderBillingAddress?addressId=1
export async function setOrderBillingAddress(addressId: number) {
  const path = `/Orders/setOrderBillingAddress?addressId=${addressId}`;
  return await fetchService({
    url: serverUrl + path,
    method: "POST",
    throwErrors: false,
  });
}

export async function createIyzicoPayment() {
  const path = "/Orders/createIyzicoPayment";
  return await fetchService({
    url: serverUrl + path,
    method: "POST",
    throwErrors: false,
  });
}
