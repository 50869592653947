import React from "react";
import { findByVehicleIdentificationId } from "../../redux/api/vehicleApi";
import { IMyVehicle, IVehicle } from "../../interfaces/garage";
import SelectedVehicle from "../selectedVehicle/SelectedVehicle";

interface IVehicleSelectorByVinProps {
  vehicle?: IVehicle;
  setVehicle(vehicle: any): void;
}

export default function VehicleSelectorByVin(
  props: IVehicleSelectorByVinProps,
) {
  const { vehicle,setVehicle } = props;
  const [vin, setVin] = React.useState<string>("");
  const [timer, setTimer] = React.useState<any>(null);
  const [notFullMatch, setNotFullMatch] = React.useState<boolean | undefined>(
    undefined,
  );
  const [possibleVehicles, setPossibleVehicles] = React.useState<any[]>([]);

  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const year = e.target.value;
    setVehicle({ ...vehicle, year });
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVin(e.target.value);
    if (e.target.value.length < 11) {
      return;
    }
    timer && clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        try {
          findByVehicleIdentificationId(e.target.value).then((res) => {
            if (!res) {
              return;
            }
            if (!res.fullMatch) {
              setNotFullMatch(true);
              setPossibleVehicles(res.possibleVehicles);
              return;
            }
            setVehicle(res.vehicle);
          });
        } catch (e) {
          console.log(e);
        }
      }, 500),
    );
  };

  return (
    <div>
      <input
        className="form-control"
        type="text"
        value={vin}
        onChange={onChange}
        placeholder="Araç VIN Numarası"
      />
      {notFullMatch && (
        <div>
          <h5>
            Aracınız bulunamadı. Aşağıdaki araçlardan birini seçebilirsiniz.
          </h5>
          {possibleVehicles?.map((vehicle: IVehicle) => (
            <>
              <div key={vehicle.id} onClick={() => setVehicle(vehicle)}>
                <SelectedVehicle myVehicle={{ vehicle } as IMyVehicle} />
              </div>
              <select onChange={handleYearChange} name="year" id="year">
                <option value="">Yıl</option>
                {vehicle.years?.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </>
          ))}
        </div>
      )}
    </div>
  );
}
