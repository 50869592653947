import { log } from "console";
import React from "react";

const BrandBlock = () => {
  const brandList = [
    {
      id: 1,
      name: "Peugeot",
      logo: "/assets/images/car-icons/peugeot.png",
    },
    {
      id: 2,
      name: "Fiat",
      logo: "/assets/images/car-icons/fiat.png",
    },
    {
      id: 3,
      name: "Chevrolet",
      logo: "/assets/images/car-icons/chevrolet.png",
    },
    {
      id: 4,
      name: "Audi",
      logo: "/assets/images/car-icons/audi.png",
    },
    {
      id: 5,
      name: "Honda",
      logo: "/assets/images/car-icons/honda.png",
    },
    {
      id: 6,
      name: "Volkswagen",
      logo: "/assets/images/car-icons/volkswagen.png",
    },
    {
      id: 7,
      name: "Ford",
      logo: "/assets/images/car-icons/ford.png",
    },
    {
      id: 8,
      name: "Mitsubishi",
      logo: "/assets/images/car-icons/mitsubishi.png",
    },
    {
      id: 9,
      name: "Opel",
      logo: "/assets/images/car-icons/opel.png",
    },
    {
      id: 10,
      name: "Hundai",
      logo: "/assets/images/car-icons/hundai.png",
    },
    {
      id: 11,
      name: "Nissan",
      logo: "/assets/images/car-icons/nissan.png",
    },
    {
      id: 12,
      name: "Renault",
      logo: "/assets/images/car-icons/renault.png",
    },
  ];
  return (
    <section className="brand">
      <div className="auto-container">
        <div className="row">
          <div className="common-section-title">
            <div className="section-title">
              <img src="/assets/images/icons/featured-nav-left.png" alt="icon" />
              <h4>Yedek Parçasını Sattığımız Markalar</h4>
            </div>
          </div>
          {brandList.map((brand) => (
            <div key={brand.id} className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
              <div className="brand-container">
                <div className="brand-logo">
                  <a href="#">
                    <img src={brand.logo} alt="logo" />{" "}
                    <span>{brand.name}</span>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BrandBlock;
