import React from 'react';

interface AuthorBoxProps {
    author: {
        name: string;
        image: string;
        description: string;
    };
}

const AuthorBox: React.FC<AuthorBoxProps> = ({ author }) => {
    return (
        <div className="author-box p_relative d_block pt_45 pr_30 pb_40 pl_170 mb_60">
            <figure className="author-thumb p_absolute l_40 t_40 w_100 h_100 b_radius_50"><img src={author.image} alt="member" /></figure>
            <h4 className="d_block fs_20 lh_30 mb_11">{author.name}</h4>
            <p className="font_family_poppins">{author.description}</p>
        </div>
    );
};

export default AuthorBox;
