import { IVehicle } from "../../interfaces/garage";
import { fetchService, serverUrl } from "./fetchService";

export interface IAddVehicle {
  name: string;
  vehicleIdentificationNumber: string;
  year:number;
}


// Function to fetch vehicles
export async function fetchVehicles() {
  const path = "/MyVehicle/getMyVehicles";
  return await fetchService({ url: serverUrl + path, method: "GET" });
}

// Function to fetch vehicles
export async function fetchVehicleById(id: number) {
  const path =
    serverUrl +
    `/MyVehicle/getMyVehicleById?vehicleId=${id}`;
  return await fetchService({ url: path + id, method: "GET" });
}

// Function to add a vehicle
export async function addVehicle(vehicle: IVehicle) {
  const path = "/MyVehicle/addMyVehicle?name="+ vehicle.vehicleName;
  const addResult = await fetchService({
    url: serverUrl + path,
    method: "POST",
    body: {
      brandId: vehicle.selections.brand,
      modelId: vehicle.selections.model,
      year: vehicle.selections.year,
      fuel: vehicle.selections.fuel,
      engine:  vehicle.selections.engine,
      gear: vehicle.selections.gear,
      gearBox: vehicle.selections.gearbox,
      horsepowerKw: vehicle.selections.horsepower,
      bodyType: vehicle.selections.bodyType,
    },
  });
  if (!addResult.success) {
    return addResult;
  }
  const vehicleData = addResult.data;
  return vehicleData
}

// Function to remove a vehicle
export async function removeVehicle(vehicleId: number) {
  const path = `/MyVehicle/deleteMyVehicle?vehicleId=${vehicleId}`;
  return await fetchService({ url: serverUrl + path, method: "DELETE" });
}
