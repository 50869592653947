import React from "react";
import { Link } from "react-router-dom";
import "./PageTitle.scss";



type PageTitleProps = {
  title: string;
};

export default function PageTitle(props: PageTitleProps) {
  return (
    <section className="page-title p_relative centred">
      <div className="pattern-layer">
        <div
          className="shape-1 p_absolute l_120 t_120 rotate-me"
          style={{ backgroundImage: "url(/assets/images/shape/shape-176.png)" }}
        ></div>
        <div
          className="shape-2 p_absolute t_180 r_170 float-bob-y"
          style={{ backgroundImage: "url(/assets/images/shape/shape-56.png)" }}
        ></div>
        <div
          className="shape-3 p_absolute l_0 b_0"
          style={{ backgroundImage: "url(/assets/images/shape/shape-189.png)" }}
        ></div>
      </div>
      <div className="auto-container">
        <div className="content-box">
          <h1 className="d_block fs_60 lh_70 fw_bold mb_10">{props.title}</h1>
          <ul className="bread-crumb p_relative d_block mb_8 clearfix">
            <li className="p_relative d_iblock fs_16 lh_25 fw_sbold font_family_inte mr_20">
              <Link to="/">Anasayfa</Link>
            </li>
            <li className="current p_relative d_iblock fs_16 lh_25 fw_sbold font_family_inte">
              {props.title}
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
