import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../configureStore";
import { IUser, IUserChangePassword, IUserEditProfile, IUserLogin, IUserRegister } from "../../interfaces/user";
import { changePassword, editProfile, login, register } from "../api/userApi";

export interface UserState {
  user?: IUser;
  status: "idle" | "loading" | "failed";
}

const initialState: UserState = {
  status: "idle",
  user: !!localStorage?.getItem("user") && localStorage?.getItem("user") !== "undefined"// Get user data if it exists
    ? JSON.parse(localStorage.getItem("user")!)
    : undefined,
};

export const loginAsync = createAsyncThunk(
  "user/signin",
  async (userInf: IUserLogin, thunkApi) => {
    try {
      const response = await login(userInf);
      // The value we return becomes the `fulfilled` action payload
      return thunkApi.fulfillWithValue(response.data);
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);

export const registerAsync = createAsyncThunk(
  "user/signup",
  async (userInf: IUserRegister, thunkApi) => {
    try {
      const response = await register(userInf);
      // The value we return becomes the `fulfilled` action payload
      return thunkApi.fulfillWithValue(response.data);
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);

export const updateProfileAsync = createAsyncThunk(
  "user/updateProfile",
  async (user: IUserEditProfile, thunkApi) => {
    try {
      const response = await editProfile(user);
      if(response.success){
        return thunkApi.fulfillWithValue(user);
      }
      
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);

export const changePasswordAsync = createAsyncThunk(
  "user/changePassword",
  async (user: IUserChangePassword, thunkApi) => {
    try {
      const response = await changePassword(user);
      if(response.success){
        return thunkApi.fulfillWithValue(user);
      }
      
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = undefined;
      // Clear local storage
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.user = action.payload;
        // Save user data to local storage
        localStorage.setItem("user", JSON.stringify(action.payload));
      })
      .addCase(loginAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(registerAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(registerAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(registerAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateProfileAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateProfileAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.user = {
          ...state.user,
          firstName: action.payload?.firstName || "",
          lastName: action.payload?.lastName || "",
          email: action.payload?.email || "",
          phone: action.payload?.phoneNumber || "",
          avatar: action.payload?.avatar || "",
          };
        // Save user data to local storage
        localStorage.setItem("user", JSON.stringify(action.payload));
      });
  },
});

export const { logout } = userSlice.actions;
export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
