import React from 'react';
import ContentOne from './ContentOne';
import ContentThree from './ContentThree';
import ContentTwo from './ContentTwo';

export interface BlogPostProps {
    contentOne: {
        category: string;
        title: string;
        date?: string;
        commentsCount?: number;
        image: string;
        text1: string;
        text2: string;
    };
    contentTwo: {
      heading: string;
      paragraph1: string;
      images: string[];
      quote: {
          text: string;
          author: string;
      };
      paragraph2: string;
    };
    contentThree: {
      title: string;
      text: string;
      listItems: string[];
    };
}

const BlogPost = (props:BlogPostProps) => {
    return (
        <div className="blog-post p_relative d_block mb_60">
            <ContentOne {...props.contentOne} />
            <ContentTwo {...props.contentTwo} />
            <ContentThree {...props.contentThree} />
        </div>
    );
};

export default BlogPost;
