import React from 'react'
import Finder from '../../component/finder/Finder'
import Banner from '../../component/banner/Banner'
import WeekDeal from '../../component/weekDeal/WeekDeal'
import CategoryProductBlock from '../../component/categoryProductBlock/CategoryProductBlock'
import BrandBlock from '../../component/brandBlock/BrandBlock'
import BlogPost from '../../component/blog/BlogPost';
import HomeBlogPage from './HomeBlogPage'

export default function Home() {
  return (
    <>
      <Banner/>
      <BrandBlock />
      <HomeBlogPage />
      
    </>
  )
}
