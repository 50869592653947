import PageTitle from "../../component/pageTitle/PageTitle";
import ProductGrid from "../../component/productGrid/ProductGrid";


export default function VehicleProduct() {
  
  return (
    <div>
      <PageTitle title="Araç Ürünleri" />
      <ProductGrid/>
    </div>
  );
}
