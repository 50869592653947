import React, { useEffect } from "react";
import {
  ICategory,
  IProduct,
  IProductResponse,
} from "../../interfaces/product";
import {
  fetchCategoriesById,
  fetchProducts,
  fetchRecommendedProducts,
} from "../../redux/api/productApi";
import { Link, useNavigate } from "react-router-dom";
import ProductGrid from "../productGrid/ProductGrid";
import { useAppDispatch } from "../../redux/hooks";
import { setSelectedCategory } from "../../redux/slices/productSlice";

interface ProductDetailProps {
  product: IProduct;
  addToCart: (product: IProduct) => void;
}

export default function ProductDetail(props: ProductDetailProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { product, addToCart } = props;
  const [category, setCategory] = React.useState<ICategory | undefined>();
  const [relatedProductsResponse, setRelatedProductsResponse] =
    React.useState<IProduct[]>([]);;

  useEffect(() => {
    if (!product.categoryId) {
      return;
    }
    fetchCategoriesById(product.categoryId + "").then((response) => {
      setCategory(response);
    });
    fetchRecommendedProducts("" + product?.id).then((response) => {
      setRelatedProductsResponse(response);
    });
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="shop-details p_relative pt_140 pb_80">
      <div className="auto-container">
        <div className="product-details-content p_relative d_block mb_100">
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image-box shope-details-left-image p_relative d_block">
                {product.imageUrls?.map((image, index) => (
                  <figure
                    className={`image ${index === 0 ? "active" : ""}`}
                    key={index}
                  >
                    <img src={image} alt="parts" />
                    <div className="preview-link p_absolute t_20 r_20">
                      <a
                        href={image}
                        className="lightbox-image p_relative d_iblock fs_20 centred z_1 w_50 h_50 color_black lh_50"
                        data-fancybox="gallery"
                      >
                        <i className="far fa-search-plus"></i>
                      </a>
                    </div>
                  </figure>
                ))}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="product-details p_relative d_block ml_20">
                <h2 className="d_block fs_30 lh_40 fw_sbold font_family_inter mb_5">
                  {product.name}
                </h2>
                <div className="customer-rating clearfix p_relative d_block mb_5">
                  <ul className="rating clearfix">
                    {Array.from({ length: 5 }, (_, index) => (
                      <li
                        key={index}
                        className={`p_relative d_iblock pull-left mr_3 fs_13`}
                      >
                        <i
                          className={`fas fa-star${index < (product.rating || 5) ? "" : " empty-star"}`}
                        ></i>
                      </li>
                    ))}
                    <li className="p_relative d_iblock pull-left fs_14">
                      <a href="shop.html" className="color_black">
                        ({product.rating || 5})
                      </a>
                    </li>
                  </ul>
                </div>
                <span className="p_relative d_block fs_20 lh_30 fw_medium color_black mb_25 font_family_inter">
                  {product.price?.toFixed(2)} TL
                </span>
                <div className="text p_relative d_block mb_30">
                  <p className="font_family_poppins mb_25">
                    {product.description}
                  </p>
                  <p className="font_family_poppins">{product.excerpt}</p>
                </div>
                <div className="addto-cart-box p_relative d_block mb_35">
                  <ul className="clearfix">
                    <li className="p_relative d_block float_left mr_10">
                      <button
                        type="button"
                        className="theme-btn theme-btn-eight"
                        onClick={() => addToCart(product)}
                      >
                        Sepete Ekle
                      </button>
                    </li>
                    <li className="p_relative d_block float_left mr_10">
                      <Link
                        to=""
                        className="d_iblock p_relative fs_20 lh_50 w_50 h_50 centred b_radius_5"
                      >
                        <i className="flaticon-heart"></i>
                      </Link>
                    </li>
                    <li className="p_relative d_block float_left mr_10">
                      <Link
                        to=""
                        className="d_iblock p_relative fs_20 lh_50 w_50 h_50 centred b_radius_5"
                      >
                        <i className="flaticon-magnifiying-glass"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="other-option">
                  <ul className="list">
                    <li className="p_relative d_block fs_16 font_family_poppins mb_5">
                      <span className="fw_medium color_black">Ürün ID:</span>{" "}
                      {product.id}
                    </li>
                    <li className="p_relative d_block fs_16 font_family_poppins mb_5">
                      <span className="fw_medium color_black">Marka:</span>{" "}
                      {product.brand.name}
                    </li>
                    <li className="p_relative d_block fs_16 font_family_poppins mb_5">
                      <span className="fw_medium color_black">Category:</span>{" "}
                      <button
                        className="btn btn-link"
                        onClick={() => {
                          if (!category) {
                            return;
                          }
                          dispatch(setSelectedCategory(category));
                          navigate("/categories/" + category.url);
                        }}
                      >
                        {category?.name}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="product-discription p_relative d_block mb_80">
          <div className="tabs-box">
            <div className="tab-btn-box p_relative d_block mb_35">
              <ul className="tab-btns tab-buttons clearfix">
                <li
                  className="tab-btn active-btn p_relative d_iblock fs_18 font_family_inter lh_20 float_left fw_medium z_1 mr_35 tran_5"
                  data-tab="#tab-1"
                >
                  Açıklama
                </li>
                <li
                  className="tab-btn p_relative d_iblock fs_18 font_family_inter lh_20 float_left fw_medium z_1 tran_5"
                  data-tab="#tab-2"
                >
                  Yorumlar (1)
                </li>
              </ul>
            </div>
            <div className="tabs-content">
              <div className="tab active-tab" id="tab-1">
                <div className="content-box">
                  <p className="font_family_poppins mb_25">
                    {product.description}
                  </p>
                </div>
              </div>
              <div className="tab" id="tab-2">
                <div className="customer-inner">
                  <div className="customer-review p_relative d_block pb_65 mb_65">
                    <h4 className="p_relative d_block fs_20 lh_30 fw_medium fw_sbold mb_40">
                      1 Review for Lenevo Laptop 15.6”
                    </h4>
                    <div className="comment-box p_relative d_block pl_110">
                      <figure className="comment-thumb p_absolute l_0 t_0 w_80 h_80 b_radius_55">
                        <img src="assets/images/product/f-5.png" alt="parts" />
                      </figure>
                      <h5 className="d_block fs_18 lh_20 fw_sbold">
                        Keanu Reeves
                        <span className="d_iblock fs_16 font_family_poppins">
                          {" "}
                          - May 1, 2021
                        </span>
                      </h5>
                      <ul className="rating clearfix mb_15">
                        <li className="p_relative d_iblock pull-left mr_3 fs_13">
                          <i className="fas fa-star"></i>
                        </li>
                        <li className="p_relative d_iblock pull-left mr_3 fs_13">
                          <i className="fas fa-star"></i>
                        </li>
                        <li className="p_relative d_iblock pull-left mr_3 fs_13">
                          <i className="fas fa-star"></i>
                        </li>
                        <li className="p_relative d_iblock pull-left mr_3 fs_13">
                          <i className="fas fa-star"></i>
                        </li>
                        <li className="p_relative d_iblock pull-left mr_5 fs_13">
                          <i className="far fa-star"></i>
                        </li>
                      </ul>
                      <div className="text">
                        <p className="fs_15 font_family_poppins">
                          Excepteur sint occaecat cupidatat non proident sunt in
                          culpa qui officia deserunt mollit anim est laborum.
                          Sed perspiciatis unde omnis natus error sit voluptatem
                          accusa dolore mque laudant totam rem aperiam eaque
                          ipsa quae ab illo inventore veritatis et quasi arch
                          tecto beatae vitae dicta.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="customer-comments p_relative">
                    <h4 className="p_relative d_block fs_20 lh_30 fw_medium fw_sbold mb_25">
                      Be First to Add a Review
                    </h4>
                    <div className="rating-box clearfix mb_12">
                      <h6 className="p_relative d_iblock fs_16 fw_medium mr_15 float_left">
                        Your Rating
                      </h6>
                      <ul className="rating p_relative d_block clearfix float_left">
                        <li className="p_relative d_iblock fs_12 lh_26 float_left mr_3">
                          <i className="far fa-star"></i>
                        </li>
                        <li className="p_relative d_iblock fs_12 lh_26 float_left mr_3">
                          <i className="far fa-star"></i>
                        </li>
                        <li className="p_relative d_iblock fs_12 lh_26 float_left mr_3">
                          <i className="far fa-star"></i>
                        </li>
                        <li className="p_relative d_iblock fs_12 lh_26 float_left mr_3">
                          <i className="far fa-star"></i>
                        </li>
                        <li className="p_relative d_iblock fs_12 lh_26 float_left">
                          <i className="far fa-star"></i>
                        </li>
                      </ul>
                    </div>
                    <form
                      action="shop-details.html"
                      method="post"
                      className="comment-form default-form"
                    >
                      <div className="row clearfix">
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group mb_15">
                          <label className="p_relative d_block fs_16 mb_3 font_family_poppins">
                            Your Review
                          </label>
                          <textarea name="message"></textarea>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group mb_15">
                          <label className="p_relative d_block fs_16 mb_3 font_family_poppins">
                            Your Name
                          </label>
                          <input type="text" name="name" required />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group mb_15">
                          <label className="p_relative d_block fs_16 mb_3 font_family_poppins">
                            Your Email
                          </label>
                          <input type="email" name="email" required />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group mb_15">
                          <div className="check-box pt_8 pb_9">
                            <input
                              className="check"
                              type="checkbox"
                              id="checkbox"
                            />
                            <label className="fs_16 font_family_poppins">
                              Save my name, email, and website in this browser
                              for the next time I comment
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn m_0">
                          <button
                            type="submit"
                            className="theme-btn theme-btn-eight"
                          >
                            Submit Your Review <i className="icon-4"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {(relatedProductsResponse?.length || 0) !== 0 && (
          <div className="related-product shop-page-2 shop-page-section">
            <div className="title-text mb_25">
              <h2 className="d_block fs_30 lh_40 fw_sbold">İlgili Ürünler</h2>
            </div>
            <div className="row clearfix">
              <ProductGrid
                categoryInactive
                pagingActive
                groupedSize={4}
                productList={
                  {
                    items: relatedProductsResponse,
                    totalCount: relatedProductsResponse.length,
                  } as IProductResponse
                }
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
