import React from "react";
import MainBlogDetailsComponent from "../../component/blog/MainBlogDetailsComponent";

const HomeBlogPage = () => {
  return (
    <div className="auto-container">
      <MainBlogDetailsComponent
        contentOne={{
          category: "Oto Yedek Parça",
          title: "Güvenilir E-Ticaret Platformu ile Oto Yedek Parça Alışverişi",
          image: "assets/images/product/blog-1-w.webp",
          text1:
            "Günümüz ticaretinin vazgeçilme bir unsuru olan güvenilir e-ticaret platformun kişisel ihtiyaçlarımızın karşılanmasında zaman ve ekonomik kazanımlarıyla vazgeçilmez bir kazanım olmaktadır. Teknolojinin gelişimi ile otomobillerimiz için ihtiyaç duyduğumuz oto yedek parça ve yedek parça ile ilgi bilgileri sağlıklı bir şekilde elde etmeniz için tamamen kullanıcı odaklı bir sistem yaratmak gayesi ile yola çıktık.",
          text2:
            "Perakende otomobil yedek parça sektöründeki otuz yıllık tecrübemizi geliştirdiğimiz Dünya Parça sistemi üzerinden sizlerle paylaşmaktan mutluluk duyarız. Usta rot diyor balans diyor siz hiç bir şey anlamıyormusunuz. Nasıl olsa anlamıyor diye düşünüp Beni kazıklıyorlar mı acaba, aracınız için kullanmanız gereken yağ hangisi acaba, periyodik bakım ne demektir, kaç kilometre yapmak gerekir, frenleme yapınca ses mi geliyor, aracım triger kayışlı mı yoksa zincirli mi aldığımız yedek parça aracıma uyumlu mu, orijinal yedek parça ile yan sanayi yedek parça arasında fiyat farkından başka ne fark var, yedek parçayı şimdi nereden bulacağız. Perakende yedek parça ve oto servis sektöründeki otuz yılı aşkın tecrübemizle oluşturduğumuz yazılımla yirmi üç araç markası 450.000 tekil yedek parçasını sizlerle paylaşmaktan mutluluk duyarız",
        }}
        contentTwo={{
          heading: "Dünya Parça",
          paragraph1:
            "Yedek Parça hizmeti size verirken kendi geliştirdiğimiz araç şase numarası ile parça sorgulayacağınız gibi kategorilerden araç oluşturarak da aracınız için doğru yedek parçaları bulabilir yedek parçanın kullanım yeri, üretici firma, marka ve fiyat bilgisini sistem üzerinden öğrenebilirsiniz. Aklınıza takılan bir sorun mu var konusunda uzman çağrı merkezimiz ile sözlü ve online iletişime geçebilir YouTube kanalımız ile sizi yedek parça konusunda görsel olarak bilgi edinebilirsiniz.",
          images: [
            "assets/images/product/blog-2.webp",
            "assets/images/product/blog-3.webp",
          ],
          quote: {
            text: "Lorem ipsum dolor amet con sectur elitadicing elit sed do usmod tempor uincididunt enim minim veniam nostrud.",
            author: "Kevin Spacey",
          },
          paragraph2:
            "Geliştirdiğimiz sistem ile aracınız için ihtiyaç duyduğunuz yedek parçayı isterseniz araç şase numarası üzerinden sorgulayabilirsiniz. Dilerseniz temel araç bilgileri ile kategorize ederek de parçalarınızı bulabilirsiniz. Uyumlu OEM (Orijinal parça kodu) numaraları ürün detaylarında yer almaktadır. Şase numarası ile yedek parça sorgulama için tıklayınız",
        }}
        contentThree={{
          title: "Dünya Parça Garaj Uygulaması",
          text: "Dünya Parça ailesi olarak geliştirdiğimiz garaj uygulaması ile site içerisinde kişiselleştirme yapabileceğiniz gibi birden çok aracı da Dünya Parça garaj uygulamasıyla yedek parça kayıtlarını saklayabilirsiniz.",
          listItems: [
            "Dünya Parça Bakım Robotu",
            "Araba Parçaları Uygun Fiyatla",
            "Birlikte Büyüyoruz",
          ],
        }}
        author={{
          name: "Henry Ford",
          image: "assets/images/icons/ford.webp",
          description:
            "Bir arabanın her parçası, mühendisliğin ve güvenilirliğin birer yansımasıdır. Doğru yedek parça, aracınızın kalbinde atmaya devam eder.",
        }}
        tags={["Creative", "Marketing", "Idea"]}
        socialLinks={[
          {
            platform: "Facebook",
            link: "blog-details.html",
            iconClass: "fab fa-facebook-f",
          },
          {
            platform: "Twitter",
            link: "blog-details.html",
            iconClass: "fab fa-twitter",
          },
          {
            platform: "Google+",
            link: "blog-details.html",
            iconClass: "fab fa-google-plus-g",
          },
        ]}
      />
    </div>
  );
};

export default HomeBlogPage;
