import {
  IProductFetchAll,
  IProductResponse,
} from "../../interfaces/product";
import { fetchService, serverUrl } from "./fetchService";

// A mock function to mimic making an async request for data
export async function fetchProducts(productListParam: IProductFetchAll): Promise<IProductResponse> {
  let path = "/Products/getProducts";
  let queryChar = "?";
  if (productListParam.categoryId){
    path += queryChar+`CategoryId=${productListParam.categoryId}`;
    queryChar = "&";
  }
  if(productListParam.vehicleId){
      path += queryChar+`VehicleId=${productListParam.vehicleId}`;
      queryChar = "&";
  }
  if (productListParam.page){
    path += queryChar+`Page=${productListParam.page}`;
    queryChar = "&";
  }
  if (productListParam){
    path += queryChar+`Limit=${productListParam.limit}`;
    queryChar = "&";
  }
  if (productListParam.sortBy !== undefined){
    path += queryChar+`SortBy=${productListParam.sortBy}`;
    queryChar = "&";
  }
  if (productListParam.sortAsc !== undefined){
    path += queryChar+`SortAsc=${productListParam.sortAsc}`;
    queryChar = "&";
  }
  if(productListParam.includeBrands !== undefined){
    path += queryChar+`IncludeBrands=${productListParam.includeBrands}`;
    queryChar = "&";
  }
  if(productListParam.includeCategories !== undefined){
    path += queryChar+`IncludeCategories=${productListParam.includeCategories}`;
    queryChar = "&";
  }  
  if(productListParam.searchQuery){
    path += queryChar+`SearchQuery=${productListParam.searchQuery}`;
    queryChar = "&";
  }
  if(productListParam.brandId){
    path += queryChar+`BrandId=${productListParam.brandId}`;
    queryChar = "&";
  }
  
  return await fetchService({
    url: serverUrl + path,
    method: "GET"
  });
}

export async function fetchMainCategory() {
  const path = "/Categories/getParentCategories";
  return await fetchService({ url: serverUrl + path, method: "GET" });
}

export async function fetchCategories() {
  const path = "/Categories/getParentCategories?loadSubCategories=true";
  return await fetchService({ url: serverUrl + path, method: "GET" });
}

export async function fetchCategoriesById(categoryId: string) {
  const path = `/Categories/getCategoryById?loadSubCategories=true&categoryId=${categoryId}`;
  return await fetchService({ url: serverUrl + path, method: "GET" });
}

export async function fetchProductsById(productId: string) {
  const path = `/Products/getProductById?ProductId=${productId}`;
  return await fetchService({ url: serverUrl + path, method: "GET" });
}

export async function fetchProductsByKey(key: string) {
  const path = `/Products/searchProduct?key=${key}`;
  return await fetchService({ url: serverUrl + path, method: "GET" });
}

// /Products/getRecommendedProducts?ProductId=1&Size=4
export async function fetchRecommendedProducts(productId: string) {
  const path = `/Products/getRecommendedProducts?ProductId=${productId}&Size=4`;
  return await fetchService({ url: serverUrl + path, method: "GET" });
}
