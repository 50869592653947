import React from 'react'
import './Footer.scss'
export default function Footer() {
  return (
    <footer className="main-footer-one mt-5">
            <div className="upper-box">
                <div className="auto-container">
                    <div className="outer-box">
                        <p>ÜCRETSİZ Kargo fırsatını yakala. Parça Dünyası kalitesiyle. </p>                          
                        <p>Süper bir indirim sepetinde </p>                          
                    </div>
                </div>
            </div>
            <div className="lower-box">
                <div className="auto-container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget footer-logo-widget">
                                <div className="footer-logo">
                                    <img src="/assets/images/logo-2.png" alt="logo"/>
                                </div>
                                <p>Yıllardır Parça Dünyası oto yedek parça şirketinin sadık bir müşterisiyim ve kesinlikle tavsiye ederim. </p>
                                <ul className="social-icon">
                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                    <li><a href="#"><i className="fab fa-dribbble"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 offset-lg-1">
                            <div className="footer-widget footer-links-widget">
                                <h4 className="footer-widget-title">Yararlı Linkler</h4>
                                <ul className="lsit">
                                    <li><a href="#">Tüm Markalar</a></li>
                                    <li><a href="#">SSS</a></li>
                                    <li><a href="#">Hizmet Şartları</a></li>
                                    <li><a href="#">Kayıt ol</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget footer-services-links-widget">
                                <h4 className="footer-widget-title">Tercih Sebebiniz</h4>
                                <ul className="lsit">
                                    <li><a href="#">Uygun Fiyat </a></li>
                                    <li><a href="#">Doğru ve Kaliteli Parçalar</a></li>
                                    <li><a href="#">Müşteri Destek Hattı</a></li>
                                    <li><a href="#">Uçtan Uca Güvenli Alışveriş</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget footer-contact-widget">
                                <h4 className="footer-widget-title">İletişim Adreslerimiz</h4>
                                <ul>
                                    <li><a href="#">İletişim</a></li>
                                    <li><a href="tel:(+123)45678900" className="footer-contact-info">(+123) 456789 00</a></li>
                                    <li><a href="mailto:sample@example.com" className="footer-contact-info">Youremail@info.com</a></li>
                                    <li><a href="#0">Our Address:</a></li>
                                    <li><a href="#0">4140 Parker Rd. Allentown, New <br/> Mexico 31134</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> 
            </div> 
            
            <div className="footer-bottom-one">
                <div className="auto-container">
                    <div className="row m-0 align-items-center justify-content-between">
                        <div className="copyright-text">Copyright © 2023 <span>Parça Dünyası</span>. All rights reserved.</div>
                        <div className="copyright-card">
                            <h6>We accept :</h6>
                            <a href="#"><img src="/assets/images/icons/card-1.png" alt="card"/></a>
                            <a href="#"><img src="/assets/images/icons/card-2.png" alt="card"/></a>
                            <a href="#"><img src="/assets/images/icons/card-3.png" alt="card"/></a>
                            <a href="#"><img src="/assets/images/icons/card-4.png" alt="card"/></a>  
                        </div>
                        <ul className="menu">
                            <li><a href="#">Hakkımızda</a></li>
                            <li><a href="#">SSS</a></li>
                            <li><a href="#">Gizlilik Politikası</a></li>
                        </ul>
                    </div>                          
                </div>
            </div>
        </footer>
  )
}
