import { useState } from "react";
import ReactCreditCards from "react-credit-cards-2";
import "react-credit-cards-2/dist/lib/styles.scss";

export interface ICreditCard {
  number: string;
  expiry: string;
  cvc: string;
  name: string;
  focus: string;
}

interface PaymentFormProps {
  creditCard: ICreditCard;
  setCreditCard: (creditCard: ICreditCard) => void;
}

const PaymentForm = (props: PaymentFormProps) => {
  const [focused, setFocused] = useState<string>("");
  const {
    creditCard = {
      number: "",
      expiry: "",
      cvc: "",
      name: "",
      focus: "",
    },
    setCreditCard,
  } = props;

  const handleInputChange = (evt: any) => {
    const { name, value } = evt.target;

    setCreditCard({ ...creditCard, [name]: value });
  };

  const handleInputFocus = (evt: any) => {
    setFocused(evt.target.name);
  };

  return (
    <div className="d-flex mt-3">
      <ReactCreditCards
        number={creditCard.number}
        expiry={creditCard.expiry}
        cvc={creditCard.cvc}
        name={creditCard.name}
        placeholders={{ name: "Ad Soyad" }}
        focused={focused as any}
      />
      <div className="container mt-2">
        <div className="col-12 form-group">
          <input
            className="form-control"
            type="creditCard"
            name="number"
            placeholder="Kart Numaranız"
            value={creditCard.number}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>

        <div className="col-12 form-group">
          <input
            className="form-control"
            type="text"
            name="name"
            placeholder="Ad Soyad"
            value={creditCard.name}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="col-12 form-group">
          <input
            className="form-control"
            type="text"
            name="expiry"
            placeholder="AA/YY Tarih"
            value={creditCard.expiry}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="col-12 form-group">
          <input
            className="form-control"
            type="number"
            name="cvc"
            placeholder="CVC"
            value={creditCard.cvc}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;
