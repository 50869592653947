import React, { useEffect, useMemo } from "react";
import "./Cart.scss";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../redux/hooks";
import { fetchCartItems, updateCartItemQuantity } from "../../redux/api/cartApi";
import {
  fetchCartsAsync,
  selectCart,
  updateCartAsync,
  updateItemInCartNonAuth,
} from "../../redux/slices/cartSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/userSlice";
import { toast } from "react-toastify";
export interface CartItem {
  id: number;
  productName: string;
  price: number;
  quantity: number;
  images?: string[];
}

export default function CartBody() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAuth = useSelector(selectUser)?.user !== undefined;
  const onRemove = async (idx: number) => {
    if (isAuth) {
      await dispatch(
        updateCartAsync({
          ...cart.items[idx],
          quantity: 0,
        }),
      );
      await dispatch(fetchCartsAsync());
    } else {
      dispatch(
        updateItemInCartNonAuth({
          ...cart.items[idx],
          quantity: 0,
        }),
      );
    }
  };
  //cartSlice'dan cart'ı alıp CartTable'a gönderiyoruz
  const cart = useSelector(selectCart);
  const cartItems = cart.items.map((c) => ({
    id: c.product.id,
    price: c.price,
    productName: c.product.name,
    quantity: c.quantity,
    images: c.product.imageUrls,
  }));
  const [itemsQuantity, setItemsQuantity] = React.useState(
    cartItems.map((item) => item.quantity),
  );

  useEffect(() => {
    setItemsQuantity(cartItems.map((item) => item.quantity));
  }, [cart.items]);

  return (
    <section className="cart-section p_relative pt_140 pb_150">
      <div className="auto-container">
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12 col-sm-12 table-column">
            <div className="table-outer">
              <table className="cart-table">
                <thead className="cart-header">
                  <tr>
                    <th>&nbsp;</th>
                    <th>Ürün adı</th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                    <th>
                      <span>Fiyat</span>
                    </th>
                    <th>Adet</th>
                    <th>Ara toplam</th>
                  </tr>
                </thead>
                <tbody>
                  {cartItems.map((item, idx) => (
                    <tr key={item.id}>
                      <td colSpan={4} className="prod-column">
                        <div className="column-box">
                          <div
                            className="remove-btn"
                            onClick={() => onRemove(idx)}
                          >
                            <i className="fa fa-times"></i>
                          </div>
                          <div className="prod-thumb">
                            <img src={item.images?.[0] || ""} alt="parts" />
                          </div>
                          <div className="prod-title">{item.productName}</div>
                        </div>
                      </td>
                      <td className="price">
                        <span>{item.price.toFixed(2)} TL</span>
                      </td>
                      <td className="qty">
                        <div className="item-quantity">
                          <input
                            className="quantity-spinner"
                            type="number"
                            value={itemsQuantity[idx]}
                            onChange={(e) => {
                              const value = parseInt(e.target.value);
                              setItemsQuantity((prev) =>
                                prev.map((item, i) =>
                                  i === idx ? value : item,
                                ),
                              );
                            }}
                            onBlur={async (e) => {
                              const itemQuantitiy = itemsQuantity[idx];
                              if (cartItems[idx].quantity !== itemQuantitiy) {
                                if (isAuth) {
                                  await dispatch(
                                    updateCartAsync({
                                      ...cart.items[idx],
                                      quantity: itemQuantitiy,
                                    }),
                                  );
                                  await dispatch(fetchCartsAsync());
                                } else {
                                  dispatch(
                                    updateItemInCartNonAuth({
                                      ...cart.items[idx],
                                      quantity: itemQuantitiy,
                                    }),
                                  );
                                }
                              }
                            }}
                            name="quantity"
                          />
                        </div>
                      </td>
                      <td className="sub-total">
                        {(item.price * item.quantity).toFixed(2)} TL
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="othre-content clearfix">
          <div className="coupon-box pull-left clearfix">
            <input type="text" placeholder="Kupon Kodu..." />
            <button type="button">
              <i className="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
        <div className="cart-total">
          <div className="row">
            <div className="col-xl-5 col-lg-12 col-md-12 offset-xl-7 cart-column">
              <div className="total-cart-box clearfix">
                <h3 className="fs_24 fw_sbold lh_30 d_block">Sepet Toplamı</h3>
                <ul className="list clearfix mb_30">
                  <li>
                    Ara Toplam:
                    <span>
                      {cartItems
                        .reduce(
                          (acc, item) => acc + item.price * item.quantity,
                          0,
                        )
                        .toFixed(2)}{" "}
                      TL
                    </span>
                  </li>
                  <li>
                    Sipariş Toplamı:
                    <span>
                      {cartItems
                        .reduce(
                          (acc, item) => acc + item.price * item.quantity,
                          0,
                        )
                        .toFixed(2)}{" "}
                      TL
                    </span>
                  </li>
                </ul>
                <button onClick={() => {
                  if (!isAuth) {
                    toast(
                      <div>
                        Oturum açmanız gerekmetedir. Buradan
                        <Link to="/login"> giriş yapabilirsiniz.</Link>
                      </div>,
                      { type: "error" },
                    );
                    return;
                  }
                  if (cartItems.length === 0) {
                    toast("Sepetinizde ürün bulunmamaktadır.", {
                      type: "warning",
                    });
                    return;
                  }
                  // Sepeti onayla
                  navigate("/order");
                }} className="theme-btn theme-btn-eight">
                  Sepeti Onayla <i className="icon-4"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
