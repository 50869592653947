import React from "react";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../redux/hooks";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/userSlice";
import {
  addCartAsync,
  addItemInCartNonAuth,
} from "../../redux/slices/cartSlice";
import { IProduct } from "../../interfaces/product";
import { toast } from "react-toastify";

interface ProductBlockProps {
  imageUrl: string;
  productName: string;
  description: string;
  productUrl: string;
  rating: number;
  price: number;
  discountedPrice: number;
  reviewCount: number;
  product: IProduct;
  addCart?: (product: IProduct) => void;
}

export default function ProductBlock({
  imageUrl,
  productName,
  description,
  productUrl,
  rating,
  price,
  discountedPrice,
  reviewCount,
  product,
  addCart,
}: ProductBlockProps) {
  const dispatch = useAppDispatch();
  const isAuth = useSelector(selectUser)?.user !== undefined;
  return (
    <div className="product-block-one week-deal-one m-1">
      <div className="ribon fs_13">Süper Fiyat</div>
      <div className="product-block-one-image">
        <div className="image">
          <Link to={productUrl}>
            <img src={imageUrl} alt={productName} />
          </Link>
        </div>
      </div>
      <div className="product-block-one-cintent">
        <h4 className="fs_15">
          <Link to={productUrl}>{productName}</Link>
        </h4>
        <h6 className="fs_15 product-name">
          <Link to={productUrl}>{description}</Link>
        </h6>
        <div className="rating">
          {Array.from({ length: 5 }, (_, index) => (
            <i
              key={index}
              className={`fas fa-star${index < rating ? "" : " empty-star"}`}
            ></i>
          ))}
          {reviewCount > 0 && <span>{reviewCount} yorum</span>}
        </div>
        <div className="price-container">
          <div className="price fs_15 fw_medium">
            {discountedPrice && discountedPrice !== 0 ? (
              <>{discountedPrice.toFixed(2)} TL</>
            ) : (
              <></>
            )}
            <span>{price.toFixed(2)} TL</span>
          </div>
          <div className="price-cart pr-4">
            <button
              onClick={async () => {
                if (addCart) {
                  addCart(product);
                  toast(
                    "Ürün bakım robotuna eklendi. Sepete eklemek için bakımı tamamlamanız gerekmektedir.",
                    {
                      type: "info",
                    },
                  );
                  return;
                }
                if (!isAuth) {
                  await dispatch(
                    addItemInCartNonAuth({
                      product: product,
                      options: [],
                      price: product.price,
                      quantity: 1,
                      total: product.price,
                    }),
                  );
                } else {
                  await dispatch(
                    addCartAsync({
                      product: product,
                      options: [],
                      price: product.price,
                      quantity: 1,
                      total: product.price,
                    }),
                  );
                }
                //ürün adı ve sayısı ile birlikte sepete eklendi mesajı göster
                toast(
                  <>
                    {`${productName} sepete eklendi`}{" "}
                    <Link to={"/cart"}>Sepeti Görüntüle</Link>
                  </>,
                  {
                    type: "success",
                  },
                );
              }}
            >
              <i className="flaticon-shopping-cart fs_25"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="product-block-one-heart">
        <a href="">
          <i className="flaticon-heart"></i>
        </a>
      </div>
    </div>
  );
}
